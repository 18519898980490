<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'RedeemCodeBundleList' }">優惠券管理</b-breadcrumb-item>
            <b-breadcrumb-item active>優惠券使用紀錄匯出</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <BelongedRedeemCode/>
  </div>
</template>

<script>
import BelongedRedeemCode from "@/components/Dashboard/RedeemCodeBundle/BelongedRedeemCode.vue"

export default {
  components: {
    BelongedRedeemCode
  }
};
</script>
